import React from 'react'
import styled, { keyframes } from 'styled-components';

const Container = styled.section`
    width: 100%;
    h3 {
        font-weight: 400;
        font-size: clamp(22px, 3vw, 28px);
        margin: 0px;
    }
`;

const fade = (color) => keyframes`
  0% {
    fill: transparent;
  }

  50% {
    fill: ${color};
  }

  100% {
    fill:transparent;
  }
`;

const Image = styled.img`
    padding: 10px 0px;
    box-sizing: border-box;
    width: 50%;
    height: auto;
    aspect-ratio: 1 / 1;
    margin: auto;
    display: block;
    margin-bottom: 50px;
    object-fit: cover;
`;

const ClassesContainer = styled.div`
    width: 100%;
`;

const Class = styled.div`
    
    .bar {
        width: 100%;
        height: 15px;
        background-color: #D9D9D9;
        position: relative;

        .percentage {
            position: absolute;
            background-color: #556BA4;
            width: ${props => props.confidence + "%"};
            height: 15px;
        }
    }
`;

const Loading = styled.div`

    svg {
        width: 30%;
        margin: auto;
        display: block;
    }

    .svg-elem-1 {
            animation: ${() => fade("rgb(43, 72, 114)")} 1.4s ease 1.8s both infinite;
    }

    .svg-elem-2 {
        animation: ${() => fade("rgb(68, 100, 127)")} 1.4s ease 1.9s both infinite;
    }

    .svg-elem-3 {
        animation: ${() => fade("rgb(111, 148, 149)")} 1.4s ease 2.0s both infinite;
    }

    .svg-elem-4 {
        animation: ${() => fade("rgb(155, 198, 172)")} 1.4s ease 2.1s both infinite;
    }


    .svg-elem-5, .svg-elem-6, .svg-elem-7, .svg-elem-8 {
        animation: ${() => fade("rgb(55, 53, 53)")} 1.4s ease 2.2s both infinite;
    }
`;

function Output(props) {
    return (
        <Container>
            {props.loading ?
                <Loading>
                    <svg viewBox="0 0 121 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.27125 29.4849C3.77788 29.6832 4.3139 29.8108 4.88678 29.8108C23.1036 29.8087 41.3038 29.8087 59.4948 29.8087C78.4411 29.8087 97.3781 29.8108 116.326 29.8108C118.544 29.8108 120.37 28.0539 120.871 25.7143C120.937 25.4067 120.976 25.0991 120.992 24.7954C121.101 22.6663 120.034 20.687 118.452 19.6649C117.914 19.2399 117.38 18.8209 116.842 18.3959C116.208 17.894 115.569 17.394 114.932 16.8941C114.672 16.6897 114.41 16.4853 114.151 16.2809C108.145 11.5531 102.138 6.82522 96.131 2.09738C95.9652 1.96575 95.7995 1.83424 95.6336 1.70469C95.3444 1.30197 94.5633 0.947808 93.5612 0.692745C93.3825 0.646255 93.2112 0.597573 93.0196 0.559157C92.187 0.393268 91.2402 0.298096 90.2933 0.298096H59.9442H30.6968C29.7905 0.298096 28.9211 0.405381 28.116 0.557196C27.8452 0.607724 27.5505 0.644178 27.3054 0.708896C26.8007 0.842484 26.3641 1.00237 26.0178 1.18049C25.6973 1.34246 25.4541 1.52254 25.3197 1.70873C25.2626 1.75326 25.2054 1.79986 25.1483 1.84439L24.8278 2.09934C21.3149 4.88023 17.8056 7.66308 14.2927 10.444C12.4211 11.9275 10.5476 13.4109 8.676 14.8966C8.29653 15.2021 7.90971 15.5037 7.52468 15.8073C6.47658 16.631 5.42101 17.4466 4.39128 18.2866C4.00625 18.6003 3.61208 18.914 3.2197 19.2277C3.07596 19.343 2.93043 19.4564 2.79046 19.5737C2.55831 19.7033 2.33361 19.8672 2.11626 20.0413C0.992555 20.946 0.100898 22.4011 0.0180537 24.1194C0.0143787 24.1944 0.00146484 24.2652 0.00146484 24.3401V24.3785C0.00146484 24.5749 0.0346444 24.763 0.0530193 24.9574C0.257558 27.0501 1.51566 28.8008 3.27125 29.4849Z" fill="#2B4872" class="svg-elem-1"></path>
                        <path d="M117.191 31.1533C117.611 31.4849 118.03 31.8138 118.451 32.1462C120.033 33.1683 121.1 35.1457 120.991 37.2769C120.974 37.5804 120.936 37.8861 120.869 38.1958C120.368 40.5333 118.543 42.2921 116.325 42.2921C97.3766 42.2921 78.4396 42.2901 59.4933 42.2901C41.3023 42.2901 23.1021 42.2901 4.88531 42.2921C4.31233 42.2921 3.77631 42.1626 3.26979 41.9663C1.5142 41.2802 0.255989 39.5294 0.0515545 37.4368C0.0330746 37.2445 0 37.0563 0 36.86V36.8215C0 36.7466 0.0128081 36.6758 0.0164831 36.6009C0.0994327 34.8806 0.990984 33.4274 2.11469 32.5228C2.33214 32.3487 2.55684 32.1848 2.789 32.0531C2.92896 31.9378 3.07449 31.8224 3.21813 31.7091C3.44724 31.5248 3.67687 31.3412 3.90514 31.158C4.22938 31.1932 4.55373 31.224 4.87796 31.224H59.4855H87.9081H116.331C116.331 31.224 116.331 31.224 116.357 31.224C116.635 31.2182 116.913 31.1901 117.191 31.1533Z" fill="#44647F" class="svg-elem-2"></path>
                        <path d="M117.191 43.4648C117.611 43.7963 118.03 44.1252 118.451 44.4577C120.033 45.4798 121.1 47.4572 120.991 49.5884C120.974 49.8919 120.936 50.1976 120.869 50.5072C120.368 52.8448 118.543 54.6036 116.325 54.6036C97.3766 54.6036 78.4396 54.6016 59.4933 54.6016C41.3023 54.6016 23.1021 54.6016 4.88531 54.6036C4.31233 54.6036 3.77631 54.474 3.26968 54.2778C1.5142 53.5916 0.255989 51.8409 0.0515545 49.7483C0.0330746 49.556 0 49.3677 0 49.1715V49.1329C0 49.0581 0.0128081 48.9872 0.0164831 48.9124C0.0994327 47.1921 0.990984 45.7389 2.11469 44.8342C2.33214 44.6602 2.55684 44.4962 2.78889 44.3646C2.92896 44.2492 3.07449 44.1339 3.21813 44.0206C3.44724 43.8362 3.67687 43.6527 3.90514 43.4695C4.22938 43.5047 4.55373 43.5355 4.87796 43.5355H59.4855H87.9081H116.331C116.331 43.5355 116.331 43.5355 116.357 43.5355C116.635 43.5296 116.913 43.5016 117.191 43.4648Z" fill="#6F9495" class="svg-elem-3"></path>
                        <path d="M117.191 55.8612C117.611 56.1928 118.03 56.5216 118.451 56.8541C120.033 57.8762 121.1 59.8536 120.991 61.9848C120.974 62.2883 120.936 62.5939 120.869 62.9037C120.368 65.2412 118.543 67 116.325 67C97.3766 67 78.4396 66.9981 59.4933 66.9981C41.3023 66.9981 23.1021 66.9981 4.88531 67C4.31233 67 3.77631 66.8705 3.26968 66.6741C1.5142 65.9881 0.255989 64.2374 0.0515545 62.1447C0.0330746 61.9524 0 61.7641 0 61.5679V61.5294C0 61.4545 0.0128081 61.3837 0.0164831 61.3088C0.0994327 59.5884 0.990984 58.1353 2.11469 57.2306C2.33214 57.0566 2.55684 56.8927 2.78889 56.7611C2.92896 56.6457 3.07449 56.5303 3.21813 56.417C3.44724 56.2327 3.67687 56.0492 3.90514 55.866C4.22938 55.9012 4.55373 55.932 4.87796 55.932H59.4855H87.9081H116.331C116.331 55.932 116.331 55.932 116.357 55.932C116.635 55.9261 116.913 55.898 117.191 55.8612Z" fill="#9BC6AC" class="svg-elem-4"></path>

                        <path d="M116.326 25.8601C97.3777 25.8601 78.4406 25.8581 59.4961 25.8581C41.3033 25.8581 23.1031 25.8581 4.88629 25.8601C4.3153 25.8601 3.77739 25.7306 3.27077 25.5342C1.94819 25.0162 0.909117 23.8929 0.389684 22.4761C0.196275 22.9902 0.0470708 23.5305 0.0194559 24.1196C0.015781 24.1944 0.00286655 24.2632 0.000976562 24.3401V24.3786C0.000976562 24.5749 0.0360467 24.7632 0.0544216 24.9554C0.257071 27.0481 1.51706 28.7988 3.27077 29.4849C3.77739 29.6812 4.3153 29.8108 4.88629 29.8108C23.1031 29.8087 41.3033 29.8087 59.4961 29.8087C78.4406 29.8087 97.3777 29.8108 116.326 29.8108C118.544 29.8108 120.369 28.052 120.87 25.7143C120.937 25.4047 120.977 25.0991 120.992 24.7955C121.034 23.996 120.909 23.2188 120.655 22.5044C119.96 24.4615 118.302 25.8601 116.326 25.8601Z" fill="#373535" fill-opacity="0.5" class="svg-elem-5"></path>
                        <path d="M116.326 38.3415C97.3777 38.3415 78.4406 38.3395 59.4961 38.3395C41.3033 38.3395 23.1031 38.3395 4.88629 38.3415C4.3153 38.3415 3.77739 38.214 3.27077 38.0156C1.94819 37.4996 0.909117 36.3763 0.389684 34.9575C0.196275 35.4717 0.0470708 36.014 0.0194559 36.6009C0.015781 36.6758 0.00286655 36.7466 0.000976562 36.8215V36.86C0.000976562 37.0564 0.0360467 37.2445 0.0544216 37.4368C0.257071 39.5295 1.51706 41.2802 3.27077 41.9664C3.77739 42.1626 4.3153 42.2921 4.88629 42.2921C23.1031 42.2902 41.3033 42.2902 59.4961 42.2902C78.4406 42.2902 97.3777 42.2921 116.326 42.2921C118.544 42.2921 120.369 40.5333 120.87 38.1958C120.937 37.8862 120.977 37.5805 120.992 37.2769C121.034 36.4775 120.909 35.7003 120.655 34.9859C119.96 36.945 118.302 38.3415 116.326 38.3415Z" fill="#373535" fill-opacity="0.5" class="svg-elem-6"></path>
                        <path d="M116.326 50.6529C97.3777 50.6529 78.4406 50.651 59.4961 50.651C41.3033 50.651 23.1031 50.651 4.88629 50.6529C4.3153 50.6529 3.77739 50.5234 3.27077 50.3271C1.94819 49.8089 0.909117 48.6857 0.389684 47.2689C0.196275 47.783 0.0470708 48.3234 0.0194559 48.9124C0.015781 48.9872 0.00286655 49.0561 0.000976562 49.1329V49.1715C0.000976562 49.3678 0.0360467 49.556 0.0544216 49.7483C0.257071 51.841 1.51706 53.5916 3.27077 54.2778C3.77739 54.474 4.3153 54.6036 4.88629 54.6036C23.1031 54.6016 41.3033 54.6016 59.4961 54.6016C78.4406 54.6016 97.3777 54.6036 116.326 54.6036C118.544 54.6036 120.369 52.8448 120.87 50.5072C120.937 50.1976 120.977 49.8919 120.992 49.5884C121.034 48.7889 120.909 48.0117 120.655 47.2973C119.96 49.2544 118.302 50.6529 116.326 50.6529Z" fill="#373535" fill-opacity="0.5" class="svg-elem-7"></path>
                        <path d="M116.326 63.0493C97.3777 63.0493 78.4406 63.0473 59.4961 63.0473C41.3033 63.0473 23.1031 63.0473 4.88629 63.0493C4.3153 63.0493 3.77739 62.9198 3.27077 62.7234C1.94819 62.2054 0.909117 61.0821 0.389684 59.6653C0.196275 60.1794 0.0470708 60.7199 0.0194559 61.3088C0.015781 61.3836 0.00286655 61.4525 0.000976562 61.5293V61.5679C0.000976562 61.7641 0.0360467 61.9524 0.0544216 62.1447C0.257071 64.2373 1.51706 65.988 3.27077 66.6742C3.77739 66.8704 4.3153 67 4.88629 67C23.1031 66.998 41.3033 66.998 59.4961 66.998C78.4406 66.998 97.3777 67 116.326 67C118.544 67 120.369 65.2412 120.87 62.9036C120.937 62.5939 120.977 62.2883 120.992 61.9848C121.034 61.1853 120.909 60.4081 120.655 59.6937C119.96 61.6508 118.302 63.0493 116.326 63.0493Z" fill="#373535" fill-opacity="0.5" class="svg-elem-8"></path>
                    </svg>
                </Loading>
                :
                <>
                    <Image src={props.preview} alt="" />
                    <ClassesContainer>
                        {Object.entries(props.predictions).map((prediction) => (
                            <Class confidence={prediction[1]}>
                                <h3>{prediction[0] + " (" + Math.round((prediction[1] + Number.EPSILON) * 100) / 100 + ")"}</h3>
                                <div className='bar'>
                                    <div className='percentage' />
                                </div>
                            </Class>
                        ))}
                    </ClassesContainer>
                </>
            }
        </Container>
    )
}

export default Output